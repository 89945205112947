<template>
<div>
  <span v-html="svgPreload" class="render-invisible"/>
  <canvas ref="canvas" class="render-invisible"/>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("svg-generator");
import { injectPath } from '@/services/utils';
import Canvg from 'canvg';
import { Mutex } from "async-mutex";

export default {
  data() {
    return {
      svgPreload: null,

      mutex: new Mutex(),
    }
  },
  mounted() {
    this.$emit('ready');
  },
  methods: {
    preload(svg) {
      log.log("preload");
      this.svgPreload = svg;
    },
    async imageFromSVG(svg, size, data) {
      log.log("imageFromSVG", svg, size, data);
      let svgText = injectPath(svg, data);
      let canvas = this.$refs.canvas;
      if (!canvas)
        return new Image();
      let release = await this.mutex.acquire();
      canvas.width = size.width;
      canvas.height = size.height;
      let ctx = canvas.getContext('2d');
      let v = await Canvg.from(ctx, svgText);
      await v.render();
      let image = new Image();
      image.src = canvas.toDataURL();
      release();
      return image;
    }
  }
}
</script>

<style>

</style>