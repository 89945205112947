<template>
<div>
  <!-- icons -->
  <div>
    <div style="position: absolute; margin: 10px 0 0 20px; z-index: 10; font-size: 2em;">
      <font-awesome-icon icon="volume-mute" :style="{ color: 'white' }" size="sm" v-if="config.muted"/>
    </div>
  </div>
  <!-- loading -->
  <div v-if="loading" class="text-center m-5">
    <div>Loading</div>
    <progress/>
  </div>
  <div v-else>
    <!-- canvas display -->
    <canvas 
      v-if="useCanvas"
      ref="canvas"
      class="canvas"
      style="border: 1px solid red"
    />
    <!-- video stream -->
    <video
      v-else-if="final"
      style="border: 1px solid #000; width:100%"
      :srcObject.prop="final"
      autoplay playsinline
      :muted="config.muted"
    />
  </div>
  <!-- svg -->
  <svg-generator 
    ref="svgGenerator"
    @ready="preloadGeneratedAssets()"
  />
  <!-- generated assets -->
  <debug-group label="Generated Assets" v-if="$debug.isOn">
    Generated Assets {{ generatedAssets }}
    <button class="btn btn-warning" @click="generatedAssets = {}">Clear</button>
    <div v-for="(a, id) in generatedAssets" :key="'ass_' + id">
      {{ id }}
      <span v-html="a.image.outerHTML"/>
    </div>
  </debug-group>
</div>
</template>

<script>
import { storage } from "@/services/db";
import { getLog } from "@/services/log";
let log = getLog("composer", true);
import { VideoStreamMerger } from "./video-stream-merger";
import SvgGenerator from '@/components/svgGenerator.vue';
import { getBrowser, fetchBlob, objectFilter } from '@/services/utils';
import { getStreamSettings, stopStream } from "@/services/mediautils";

export default {
  components: {
    SvgGenerator
  },
  props: {
    template: Object,
    layoutConfig: {
      type: Object,
      default: () => { return {}; },
    },
    users: Array,
    config: {
      type: Object,
      default: () => { return {}; },
    }
  },
  data() {
    return {
      userSlots: ['A', 'B', 'C', 'D'],
      loading: false,
      assets: {},
      generatedAssets: {},
      final: null,
      scaleFactor: 1,
      useCanvas: false,
      gsPublicURLPrefix: null,//"https://storage.googleapis.com/livem-demo.appspot.com/studio/",
    }
  },
  watch: {
    async template() {
      this.loadTemplate();
    },
    async layoutConfig() {
      //log.log("layoutConfig changed");
      await this.setLayoutConfig(this.layoutConfig);
    },
    async users() {
      //log.log("users changed");
      await this.setLayoutConfig(this.layoutConfig);
    }
  },
  async mounted() {
    log.log("mounted");
    // __ iOS Specific
    if (this.config.forceCanvas || ['mobilesafari', 'live-wrapper'].includes(getBrowser()))
      this.useCanvas = true;
    // ^^ iOS Specific
    await this.loadTemplate();
  },
  beforeDestroy() {
    log.log("beforeDestroy");
    if (this.final)
      stopStream(this.final);
    if (this.merger)
      this.merger.destroy();
  },
  methods: {
    async loadTemplate() {
      log.log("loadTemplate", this.template?.id, this.layoutConfig);
      if (this.template) {
        this.loading = true;
        let pa = this.prepareAssets(this.template);
        let pga = this.prepareGeneratedAssets(this.template);
        await Promise.all([pa, pga]);
        await this.startComposer();
        this.loading = false;
      }
    },
    // Loading assets
    async urlFromCloudStorage(path) {
      if (!this.storageRef) {
        this.storageRef = storage.refFromURL("gs://livem-demo.appspot.com/studio");
      }
      let url = await this.storageRef.child(path).getDownloadURL();
      //log.log("assetURL=", url);
      return url;
    },
    async loadTextFile(path) {
      let url = await this.urlFromCloudStorage(path);
      log.log("textFileURL=", url);
      let blob = await fetchBlob(url);
      return blob.text();
    },
    async loadImage(elem, url) {
      //log.log("loadImage url=", url);
      return new Promise((resolve, reject) => {
        elem.onload = async () => {
          //log.log("image loaded");
          await elem.decode();
          resolve(elem);
        }
        elem.onerror = reject;
        elem.src = url;
      });
    },
    async prepareAssets(template) {
      log.log("prepareAssets", template.assets);
      this.assets = {};
      let promises = [];
      // loading assets
      for (let key in template.assets) {
        let a = template.assets[key];
        if (!a.path) {
          log.log(`skipping ${key}`);
          continue;
        }
        let p = new Promise(async (resolve, reject) => {
          try {
            if (this.gsPublicURLPrefix) {
              log.log("Loading asset (direct) path=", a.path);
              a.image = new Image(a.width);
              a.url = this.gsPublicURLPrefix + a.path;
              await this.loadImage(a.image, a.url);
            } else {
              log.log("Loading asset path=", a.path);
              if (a.path.startsWith("http"))
                a.url = a.path;
              else
                a.url = await this.urlFromCloudStorage(a.path);
              let img = new Image(a.width, a.height);
              // 1 try to load asset into an image.
              let blob = await fetchBlob(a.url);
              await this.loadImage(img, URL.createObjectURL(blob));
              a.image = img;
            }
            // 2 load the image an copy it into a canvas2d.
            // a.image = await this.loadImage(img, a.url);// a.img);
            //log.log(`${key} a=`, a, a.image.naturalWidth, a.image.naturalHeight);
            this.$set(this.assets, key, a);
            resolve(a);
          } catch (e) {
            log.error("asset error", key, a.path, e);
            reject(e);
          }
        });
        promises.push(p);
      }
      await Promise.all(promises);
    },
    // Generated assets
    async prepareGeneratedAssets(template) {
      log.log("prepareGeneratedAssets");
      this.generatedAssets = {};
      for (let key in template.assets) {
        let a = template.assets[key];
        if (!a.generated)
          continue;
        let svgAsset = {
          svg: await this.loadTextFile(a.generated.path),
          width: a.generated.width,
          height: a.generated.height,
        };
        a.svgAsset = svgAsset;
        this.$refs.svgGenerator?.preload(a.svgAsset.svg);
        this.$set(this.assets, key, a);
      }
    },
    async preloadGeneratedAssets() {
      log.log("preloadGeneratedAssets", this.assets);
      if (!this.$refs.svgGenerator) {
        log.error("svgGenerator missing");
        return;
      }
      Object.entries(this.assets).forEach(([, a]) => {
        if (a.svgAsset)
          this.$refs.svgGenerator.preload(a.svgAsset.svg);
      });
    },
    async updateUserAsset(user) {
      log.log("updateUserAsset", user, this.assets["banner"]);
      if (!this.assets?.banner?.svgAsset?.svg) {
        log.log("banner asset missing, will retry when assets are loaded");
        return;
      }
      if (!this.$refs.svgGenerator) {
        log.error("svgGenerator missing");
        return;
      }
      let image = await this.$refs.svgGenerator.imageFromSVG(this.assets["banner"].svgAsset.svg, this.assets["banner"].generated, {user});
      let banner = objectFilter(this.assets["banner"], (v, k) => ['width','height'].includes(k));
      this.$set(this.generatedAssets, user.id, Object.assign(banner, {image}));
      log.log("updateUserAsset image=", this.generatedAssets[user.id]);
      this.setLayoutConfig(this.layoutConfig);
      return this.generatedAssets[user.id];
    },
    async updateSlateAsset(slate) {
      log.log("updateSlateAsset", this.assets["slateText"]);
      let image = await this.$refs.svgGenerator.imageFromSVG(this.assets["slateText"].svgAsset.svg, this.assets["slateText"].generated, {slate});
      let asset = Object.assign({}, this.assets["slateText"]);
      this.$set(this.assets, "slateText", Object.assign(asset, {image}));
      this.setLayoutConfig(this.layoutConfig);
    },
    // Mapping assets
    async setUsersAssets() {
      log.log("setUsersAssets", this.users, this.layoutConfig, this.userSlots);
      for (let index = 0; index < this.userSlots.length; ++index) {
        let user = this.users[index] || {};
        let userSlot = this.userSlots[index];
        // Remapping
        if (this.layoutConfig.users) {
          let userIndex = this.layoutConfig.users.findIndex((e) => e == userSlot);
          if (userIndex < 0)
            continue; // skip
          userSlot = this.userSlots[userIndex];
          log.log(`remapping ${user.id} to`, userSlot);
        }
        this.assets["stream" + userSlot] = user.stream;
        this.assets["screen" + userSlot] = user.screen;
        if (user.id) {
          if (!this.generatedAssets[user.id])
            this.$set(this.generatedAssets, user.id, await this.updateUserAsset(user));
          this.assets["banner" + userSlot] = this.generatedAssets[user.id];
        } else {
          this.assets["banner" + userSlot] = null;
        }
      }
      log.log("setUsersAssets assets=", this.assets);
    },
    // Setup Layout
    async setLayoutConfig(config) {
      log.log(`setLayoutConfig ${config?.variant} ${config?.users}`);
      if (!config || !this.merger)
        return;
      this.merger.removeAll();
      let layout = this.template.layouts[config.id];
      if (config.variant)
        layout = this.template.layouts[config.id][config.variant];
      await this.setUsersAssets();
      this.setLayout(layout, this.template.dest);
    },
    setLayoutId(value, old) {
      log.log("setLayoutId=", value, old);
      this.setLayoutConfig({id:value});
    },
    async startComposer() {
      log.log("startComposer");
      if (!this.merger) {
        log.log("Creating merger");
        let options = Object.assign({}, this.template.dest);
        if (this.useCanvas) options.canvas = this.$refs.canvas;
        if (this.config.debug) options.debug = true;
        this.merger = new VideoStreamMerger(options);
        this.merger.start();
      }
      if (this.layoutConfig) {
        log.log("setting layout", this.layoutConfig);
        this.setLayoutConfig(this.layoutConfig);
      }
      else 
        log.log("no layout selected");
      this.final = this.merger.result;
      log.log("startComposer complete", this.final);
      this.$emit("stream-composed", this.final);
    },
    removeLayout(layout) {
      log.log("removeLayout");
      for (let l of layout.layers) {
        log.log("remove layer=", l);
        if (l.asset.startsWith("stream")) {
          let asset = this.assets[l.asset];
          if (asset && asset.stream)
            this.merger.removeStream(asset.stream);
        } else {
          this.merger.removeStream(l.id);
        }
      }
    },
    setLayout(layout, dest) {
      log.log("setLayout", layout);
      // eslint-disable-next-line no-console
      // console.trace();
      let m = this.merger;
      for (let l of layout.layers) {
        //log.log("add layer=", l);
        let asset = this.assets[l.asset];
        if (!asset) {
          //log.log("layer not added: missing asset", l.asset);
          continue;
        } else {
          if (asset.stream)
            this.addStream(m, asset, l, dest);
          else if (asset.image)
            this.addImage(m, asset, l, dest);
          else if (asset.media)
            this.addMediaElement(m, asset, l, dest);
          else
            log.log("asset empty", l.asset);
        }
      }
    },
    getAssetDimensions(asset) {
      if (asset.media)
        return {
          width: asset.media.videoWidth,
          height: asset.media.videoHeight,
          aspectRatio: asset.media.videoWidth / asset.media.videoHeight,
        };
      else if (asset.stream)
        return getStreamSettings(asset.stream);
    },
    computeSourceRect(asset, layer) {
      let srcInfo = this.getAssetDimensions(asset);
      if (asset.fit == 'contain') {
        let fill = {
          color: asset.backgroundColor,
          ...layer,
        }
        let s = {x:0, y:0, width:srcInfo.width, height:srcInfo.height};
        let destRatio = layer.width / layer.height;
        let d;
        if (destRatio < srcInfo.aspectRatio) {
          let rh = layer.width / srcInfo.aspectRatio;
          d = {x:layer.x, width:layer.width, y:layer.y+(layer.height - rh)/2, height:rh};
        } else {
          let rw = layer.height * srcInfo.aspectRatio;
          d = {x:layer.x+(layer.width - rw)/2, width:rw, y:layer.y, height:layer.height};
        }
        return [s, d, fill];
      } if (asset.fit == 'fill') {
        let s = {x:0, y:0, width:srcInfo.width, height:srcInfo.height};
        return [s, layer];
      } else {
        let destRatio = layer.width / layer.height;
        let s;
        if (destRatio > srcInfo.aspectRatio) {
          let rh = srcInfo.width / destRatio;
          s = {x:0, width:srcInfo.width, y:(srcInfo.height - rh)/2, height:rh};
        } else {
          let rw = srcInfo.height * destRatio;
          s = {x:(srcInfo.width - rw)/2, width:rw, y:0, height:srcInfo.height};
        }
        return [s, layer];
      }
    },
    addStream(merger, asset, layer, destRect) {
      log.log("addStream", asset.stream, layer, destRect);
      merger.addStream(asset.stream, Object.assign(layer, {
        mute: asset.mute,
        draw: (ctx, frame, done) => {
          if (asset.stream) {
            if (!layer.audioOnly) {
              let [s, d, fill] = this.computeSourceRect(asset, layer);
              if (fill) {
                ctx.fillStyle = fill.color;
                ctx.fillRect(fill.x, fill.y, fill.width, fill.height);
              }
              if (asset.mirror) {
                ctx.save();
                ctx.scale(-1, 1);
                ctx.drawImage(frame, s.x, s.y, s.width, s.height, -d.x, d.y, -d.width, d.height);
                ctx.restore();
              }
              else 
                ctx.drawImage(frame, s.x, s.y, s.width, s.height, d.x, d.y, d.width, d.height);
            }
            done();
          }
        }
      }));
    },
    addMediaElement(merger, asset, layer, destRect) {
      log.log("addMediaElement", asset, asset.media, layer, destRect);
      merger.addMediaElement(asset.id, asset.media, Object.assign(layer, {
        mute: asset.mute,
        draw: (ctx, frame, done) => {
          if (asset.media) {
            if (!layer.audioOnly) {
              let [s, d, fill] = this.computeSourceRect(asset, layer);
              if (fill) {
                ctx.fillStyle = fill.color;
                ctx.fillRect(fill.x, fill.y, fill.width, fill.height);
              }
              if (asset.mirror) {
                ctx.save();
                ctx.scale(-1, 1);
                ctx.drawImage(frame, s.x, s.y, s.width, s.height, -d.x, d.y, -d.width, d.height);
                ctx.restore();
              }
              else 
                ctx.drawImage(frame, s.x, s.y, s.width, s.height, d.x, d.y, d.width, d.height);
            }
            done();
          }
        }
      }));
    },    
    addImage(merger, asset, layer, destRect) {
      log.log("addImage", asset, layer, destRect);
      let rect = {x: 0, y: 0, width:asset.width, height:asset.height};
      if (layer.alignment == "br")
        Object.assign(rect, {
          x: destRect.width - asset.width,
          y: destRect.height - asset.height
        });
      else if (layer.alignment == "bl")
        Object.assign(rect, {
          y: destRect.height - asset.height
        });
      else if (layer.alignment == "fullscreen")
        Object.assign(rect, destRect);
      else {
        Object.assign(rect, layer);
      }
      log.log(`${layer.id} rect=`, rect, asset);
      merger.addStream(layer.id, {
        index: layer.index,
        draw: (ctx, frame, done) => {
          ctx.drawImage(asset.image, rect.x, rect.y, rect.width, rect.height);
          done();
      }});
    },        
  }
}
</script>

<style scoped>

.canvas {
  width: 100%;
}

</style>