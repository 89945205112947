<template>
<div class="m-2">
  <composer
    :template="template"
    :layout-id="layoutSelect"
    :override-assets="assets"
    @stream-composed="composerStreamComposed"/>
  <hr/>
  <div class="form-inline sub-mt-2 sub-mr-2">
    <div>Template: </div>
    <b-form-select v-model="templateSelect" :options="templateOptions"/>
    <debug-obj v-if="$debug.isDeveloperOn" :objData="templates"/>
  </div>
  <div class="form-inline sub-mt-2 sub-mr-2">
    <div>Layout: </div>
    <button class="btn btn-primary" @click="layoutSelect = id" v-for="id in layoutOptions" :key="id">{{ id }}</button>
  </div>
  <hr/>
  <!-- Asset mapping -->
  <div class="row" v-for="key in assetKeys" :key="key">
    <div class="col-md-2">{{key}}</div>
    <div class="col-md-4">
      <b-form-select v-model="editAssets[key]" :options="assetOptions"></b-form-select>
    </div>
  </div>
  <button class="btn btn-primary" @click="assignStreamAssets">Save</button>
  <hr/>
  <!-- Test Assets -->
  <video src="/abby.mov" ref="assetTest0" width="400px" autoplay loop muted style="display:none;"/>
  <video src="/dolapo.mov" ref="assetTest1" width="400px" autoplay loop muted style="display:none;"/>
  <video src="/waiting.mp3" ref="assetTest2" width="400px" autoplay loop muted style="display:none;"/>
  <video src="/waves.mp3" ref="assetTest3" width="400px" autoplay loop muted style="display:none;"/>
  <!-- Cloud Assets -->
  <debug-group label="See Assets">
    <div class="d-flex">
      <div v-for="(a, id) in assets" class="assetpreview" v-bind:key="'asset_'+id">
        <debug-obj :label="id" :objData="a" :folded="true"/>
        <video v-if="a.stream" muted autoplay playsinline :srcObject.prop="a.stream"/>
        <span v-else-if="a.image">Loaded<span v-html="a.image.outerHTML"/></span>
        <span v-else>Asset not loaded</span>
      </div>
    </div>
  </debug-group>
  <hr/>
  <streaming-editor v-if="useRecorder"
    :stream="final"
    :config="{hlsBucket:'test-studio2'}"/>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("studio");
import { db } from "@/services/db";
import Composer from '@/components/composer.vue';
import DebugGroup from '../../components/debugGroup.vue';
import DebugObj from '../../components/debugObj.vue';
import { bindMap } from '../../services/utils';
import StreamingEditor from '../../components/streamRecorder.vue';
import { bindInheritedObj } from '../../services/dbutils';

export default {
  components: {
    DebugGroup,
    DebugObj,
    Composer,
    StreamingEditor,
  },
  data() {
    return {
      roomId: "tOeLviulNXEnmjrOMvqr",
      room: null,
      final: null,

      isRecording: false,
      users: [],

      assets: {},
      editAssets: {},
      assetKeys: [],
      assetOptions: [],
      assetOptionsTestStream: [],

      templateSelect: null,
      template: null,
      template_obj: null,
      templates: null,
      templates_asArray: null,
      layoutSelect: null,

      useRecorder: false,
    }
  },
  computed: {
    templateOptions() {
      if (!this.templates)
        return [];
      return Object.keys(this.templates).map(v => {
        return {value:v, text:this.templates[v].id};
      });
    },
    layoutOptions() {
      if (!this.template)
        return [];
      return Object.keys(this.template?.layouts).sort((a, b) => a.localeCompare(b));
    }
  },
  watch: {
    async templateSelect() {
      log.log("templateSelect=", this.templateSelect);
      await bindInheritedObj(this, "template", db.collection("LiveStudioTemplates"), this.templateSelect);
      log.log("template", this.template);
      this.assets = this.template.assets;
      this.layoutSelect = this.template.init.layout;
      this.initAssetsOptions();
      this.editAssets = Object.assign({}, this.assets);
    },
  },
  mounted() {
    this.$debug.isOn = true;
    this.init();
  },
  methods: {
    async init() {
      this.loadTestStream();
      await this.loadTemplates();
      this.listStreamAssets();
      this.initAssetsOptions();
    },
    async loadTemplates() {
      await bindMap(this, "templates", db.collection("LiveStudioTemplates").where("display", "==", true));
      log.log("templates=", this.templates);
    },
    async webRTCUsersChanged(users) {
      this.users = users;
      this.listStreamAssets();
    },
    composerStreamComposed(stream) {
      this.final = stream;
    },
    joinRoom() {
      this.$bind("room", db.collection("LiveRooms").doc(this.roomId));
    },
    loadTestStream() {
      try {
        this.testStreams = [
          this.$refs.assetTest0.captureStream(),
          this.$refs.assetTest1.captureStream(),
          this.$refs.assetTest2.captureStream(),
          this.$refs.assetTest3.captureStream(),
        ];
        // Sets to very low volume but not 0 otherwise it mutes.
        this.$refs.assetTest2.volume = 0.01;
        this.$refs.assetTest3.volume = 0.01;
        this.assetOptionsTestStream.push(
          {text: "(test) Stream Abby", value: "test-abby"},
          {text: "(test) Stream Dolapo", value: "test-dolapo"},
          {text: "(test) Audio Idle Music", value: "test-idle"},
          {text: "(test) Audio Waves", value: "test-waves"}
        );
      } catch (err) {
        log.log("loadTestStream failed", err);
      }
    },
    initAssetsOptions() {
      log.log("initAssetsOptions");
      // keys
      if (this.assets) {
        this.assetKeys = Object.keys(this.assets).sort((a, b) => a.localeCompare(b));
        log.log("listStreamAssets=", this.assetKeys);
      }
      // room bindings
      let roomStreams = [
        { text: "User 0 stream", value: "users[0].stream" },
        { text: "User 1 stream", value: "users[1].stream" },
        { text: "User 2 stream", value: "users[2].stream" },
      ];
      // default assets
      let templateAssets = [];
      this.assetKeys.forEach(key => {
        if (!this.assets[key].type)
          templateAssets.push({text: `(default) ${this.assets[key].path}`, value: this.assets[key]});
      });
      // Combine all assets
      this.assetOptions = templateAssets.concat(this.assetOptionsTestStream, roomStreams);
    },
    listStreamAssets() {
    },
    assignStreamAssets() {
      let streamsMap = {
        "users[0].stream": {stream: this.users.length > 0 ? this.users[0].stream : null},
        "users[1].stream": {stream: this.users.length > 1 ? this.users[1].stream : null},
        "users[2].stream": {stream: this.users.length > 2 ? this.users[2].stream : null},
        "test-abby": {stream: this.testStreams[0]},
        "test-dolapo": {stream: this.testStreams[1], mute: true},
        "test-idle": {stream: this.testStreams[2]},
        "test-waves": {stream: this.testStreams[3]},
      };
      let assets = Object.assign({}, this.editAssets);
      this.assetKeys.forEach(k => {
        if (typeof assets[k] == 'string')
          assets[k] = streamsMap[assets[k]];
      });
      this.assets = assets;
      log.log("assign asset=", this.assets);
    },
    deleteComposer() {
      this.merger.destroy();
      delete this.merger;
      this.final = null;
    },
  }
}
</script>

<style scoped>
.assetpreview {
  width: 300px;
  object-fit: contain;
}
</style>
